import React, { useEffect, useRef } from 'react';
import logo from './svg/logo.svg';
import cookie from './svg/cookie.svg';
import gdpr from './svg/gdpr.svg';
import copyright from './svg/w-copyright.svg';
import privacyPolicy from './svg/w-privacy-policy.svg';
import termsAndConditions from './svg/terms-and-conditions.svg';
import legalGavel from './svg/w-legal-gavel.svg';
import './App.css';

const App = () => {
  const divRef = useRef(null);

  const [legalShow, setLegalShow] = React.useState(false);

  useEffect(() => {
    if (divRef.current === null) return;
    // @ts-ignore
    divRef.current.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
  }, [divRef]);


  const toggle = (event: {preventDefault: () => void;}): void => {
    event.preventDefault();
    setLegalShow(!legalShow);
    document.getElementsByClassName("closed")[0].classList.toggle("opened");
    document.getElementsByClassName("legalMenu")[0].classList.toggle("iconRotate45");
  }

  return (
    <div className="App" ref={divRef}>
      <div className="neon-body">
        <h1 className="neonText">
          <span>kuetify.com</span>
        </h1>
      </div>
      <div className="header-one">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
          <p className="coming-soon">COMING SOON</p>
        </header>
      </div>
      <div className="footer-one">
        <footer className="footer">
          <div className="closed">
            <a href="javascript:document.dispatchEvent(new Event('cookie_consent_show'))" className="footer-near">
              <img src={cookie} className="svg-m" alt="Cookie Settings" title="Cookie Settings"/>
              <span className="smallLabels">Cookie</span>
            </a>
            <a href="/legal/gdpr.html" className="footer-near">
              <img src={gdpr} className="svg-m" alt="GDPR" title="GDPR"/>
              <span className="smallLabels">GDPR</span>
            </a>
            <a href="/legal/privacy-policy.html" className="footer-near">
              <img src={privacyPolicy} className="svg-m" alt="Privacy Policy" title="Privacy Policy"/>
              <span className="smallLabels">Privacy Policy</span>
            </a>
            <a href="/legal/terms.html" className="footer-near">
              <img src={termsAndConditions} className="svg-m" alt="Terms and Conditions"
                   title="Terms and Conditions"/>
              <span className="smallLabels">Terms</span>
            </a>
            <a href="/legal/copyright.html" className="footer-near">
              <img src={copyright} className="svg-m" alt="Copyright" title="Copyright"/>
              <span className="smallLabels">Copyright</span>
            </a>
          </div>
          <a href="#" onClick={toggle} className="footer-near">
            <img src={legalGavel} className="svg-m legalMenu" alt="Legal" title="Legal"/>
            <span className="smallLabels">Legal Information</span>
          </a>
        </footer>
      </div>
    </div>
  );
};

export default App;
